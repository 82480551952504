import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/heroSubpage'
import CardIcons from '../../components/cardIcons.js'
import Cta from '../../components/cta'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fat } from '@fortawesome/pro-thin-svg-icons'
import Card from '../../components/card'


library.add(fat)

export default ({ data, location }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <div id="sub-page">
      <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.fluid.src}          
        />

        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.fluid}
          foreimage={null}
          imageAltText={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}          
          type="root"
        />
      </div>

      <main>
        <section className="bg-white">
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  <div
                    className="text-xs-extra-large divlink text-opening-para"
                    dangerouslySetInnerHTML={{ __html: post.frontmatter.openingpara }}
                  />
                  <div
                    className="mt-3 richtext divlink"
                    dangerouslySetInnerHTML={{ __html: post.html }}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBAnimation>
        </section>

        {post.frontmatter.section.map((sections, index) => (
          <section className="bg-light-blue-prods" key={index}>
            <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
              <MDBContainer>
                <MDBRow>
                  <MDBCol md="12" className="pr-5 mb-5">
                    <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">
                      {sections.title}
                    </h2>
                    <div
                      className="mt-3 font-w-400 text-medium"
                      dangerouslySetInnerHTML={{ __html: sections.description }}
                    />
                  </MDBCol>
                </MDBRow>

                <MDBRow className="mb-5">
                  <MDBCol md="3">
                    <h3 className="font-alt font-w-600 letter-spacing-1 text-blue-dark title-xs-medium title-medium">
                      Features
                    </h3>
                  </MDBCol>

                  <MDBCol md="9">
                    <MDBRow>
                      {sections.feature.map((features, index) => (
                        <MDBCol md="4" className="pb-4 item-prods" key={index}>
                          <div className="feature-icon">
                            <FontAwesomeIcon
                              icon={['fat', features.icon]}
                              size="3x"
                            />
                          </div>
                          <p className="text-medium text-blue-dark font-w-700">
                            {features.title}
                          </p>
                        </MDBCol>
                      ))}
                    </MDBRow>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol md="3">
                    <h3 className="font-alt font-w-600 letter-spacing-1 text-blue-dark title-xs-medium title-medium">
                      Benefits
                    </h3>
                  </MDBCol>

                  <MDBCol md="9">
                    <MDBRow>
                      {sections.benefit.map((benefits, index) => (
                        <MDBCol md="4" className="pb-4 item-prods" key={index}>
                          <div className="feature-icon">
                            <FontAwesomeIcon
                              icon={['fat', benefits.icon]}
                              size="3x"
                            />
                          </div>
                          <p className="text-medium text-blue-dark font-w-700">
                            {benefits.title}
                          </p>
                        </MDBCol>
                      ))}
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBAnimation>
          </section>
        ))}

        <section className="bg-white">
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-5">
                {post.frontmatter.whyworkuei.title}
              </h3>
              {post.frontmatter.whyworkuei.whywork && (
                <MDBRow>
                  <>
                    {post.frontmatter.whyworkuei.whywork.map(workwith => {
                      return (
                        <CardIcons
                          collg="4"
                          colmd="6"
                          title={workwith.title}
                          imageUrl={workwith.image.childImageSharp.fixed}
                          imageAltText={workwith.alttext}
                        />
                      )
                    })}
                  </>
                </MDBRow>
              )}
            </MDBContainer>
          </MDBAnimation>
        </section>

        {post.frontmatter.helpfulresources && (
        <section className="bg-white-grey-gradient">
          <MDBAnimation reveal type="fadeIn" duration="1587ms">
            <MDBContainer>
            <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left">
                {post.frontmatter.helpfulresources.title}
              </h2>
              {post.frontmatter.helpfulresources.helpfulres && (
                <MDBRow className="pt-5">
                  <>
                    {post.frontmatter.helpfulresources.helpfulres.map(
                      helpfulres => {
                        return (
                          <Card
                            collg="4"
                            colmd="6"
                            height="6.5rem"
                            title={helpfulres.title}
                            subtitle={helpfulres.subtitle}
                            description={helpfulres.description}
                            imageUrl={helpfulres.image.childImageSharp.fluid}
                            imageAltText={helpfulres.alttext}
                            placement={helpfulres.placement}
                            link={
                              helpfulres.link
                                ? helpfulres.link
                                : helpfulres.document.publicURL
                            }
                            titleclass="title-small"
                            descriptionClass="text-card-small"
                          />
                        )
                      }
                    )}
                  </>
                </MDBRow>
              )}
            </MDBContainer>
          </MDBAnimation>
        </section>
      )}

        <section className="bg-blue-green-gradient">
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <Cta
                ctatitle={post.frontmatter.cta.title}
                ctadescription={post.frontmatter.cta.description}
                ctalink={post.frontmatter.cta.link}
                ctalinktext={post.frontmatter.cta.linktext}
              />
            </MDBContainer>
          </MDBAnimation>
        </section>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }     
    markdownRemark(frontmatter: { name: { eq: "services" } }) {
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        alttext
        openingpara
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alttext
        ctatext
        ctaslug        
        section {
          title
          description
          feature {
            title
            icon
          }
          benefit {
            title
            icon
          }
        }
        whyworkuei {
          title
          whywork {
            title
            alttext
            image {
              childImageSharp {
                fixed(width: 70, height: 70, quality: 90) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
        helpfulresources {
          title
          helpfulres {
            title
            subtitle
            description
            placement
            link
            alttext
            image {
              childImageSharp {
                fluid(maxWidth: 960, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            document {
              publicURL
            }
          }
        }
        cta {
          title
          description
          linktext
          link
        }
      }
      html
    }
  }
`
